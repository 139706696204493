"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('slick-carousel');
function InitHartwall() {
    window.addFullWidth = function () {
        if (!$(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").addClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").addClass("has-bg-image");
        }
    };
    window.removeFullWidth = function () {
        if ($(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").removeClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").removeClass("has-bg-image");
        }
    };
    // Novelle 2020
    if ($('.o-hero-container').hasClass('novelle-2020')) {
        $('body').addClass('novelle-2020');
        window.setSliderImage = function () {
            //console.log(window.innerWidth);
            if (window.innerWidth < 1080) {
                $('.o-hero-container .activated .o-hero-slide').css('background-image', '');
                var dataMobileUrl = $('.o-hero-container .o-hero-slide').data('mobile-url');
                $('.o-hero-container .o-hero-slide').css('background-image', 'url(' + dataMobileUrl + ')');
            }
            if (window.innerWidth >= 1080) {
                $('.o-hero-container .o-hero-slide').css('background-image', '');
                var dataDesktopUrl = $('.o-hero-container .o-hero-slide').data('desktop-url');
                $('.o-hero-container .o-hero-slide').css('background-image', 'url(' + dataDesktopUrl + ')');
            }
        };
        $(window).resize(function () {
            window.setSliderImage();
        });
        window.isMobileDevice = function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                // true for mobile device
                return true;
            }
            else {
                // false for not mobile device
                return false;
            }
        };
        // init
        $('html').css('scroll-behavior', 'smooth');
        window.setSliderImage();
        $('.novelle-2020 .o-hero-slide').css('visibility', 'visible');
        if (window.isMobileDevice()) {
            $('body').addClass('mobile');
        }
    }
    // Tasting Days
    //if (jQuery('.block').hasClass('tasting-days')) {
    //    $(document).ready(function () {
    //        $('.tasting-days').slick();
    //    });
    //}
}
exports.InitHartwall = InitHartwall;
