"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var BreadCrumbNavigation_1 = require("./BreadCrumbNavigation");
var Hero = /** @class */ (function (_super) {
    __extends(Hero, _super);
    function Hero() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Hero.prototype.render = function () {
        var backgoundImageUrl = this.props.backgroundImageUrl;
        var hasBackgroundImage = backgoundImageUrl && backgoundImageUrl.length > 0;
        var heading = this.props.heading;
        var ingressText = this.props.ingressText;
        var dimFilter = this.props.dimFilter;
        var link = this.props.link;
        var linkText = this.props.linkText;
        var hasLink = link && linkText;
        var showBreadCrumbNavigation = !(this.props.hideBreadcrumbNavigation);
        var backgroundImageStyle = React.createElement("style", null);
        var dimFilterStyle = {};
        if (hasBackgroundImage) {
            dimFilterStyle.backgroundColor = 'black';
            dimFilterStyle.opacity = 0.25;
            backgroundImageStyle = React.createElement("style", { dangerouslySetInnerHTML: {
                    __html: "@media all and (max-width: 600px) {\n                        .product-listing-background-image {\n                            background-image: url('" + backgoundImageUrl + "?width=800&CropMode=percentage&crop=18,0,18,0');\n                            background-size: cover;\n                            background-repeat: no-repeat;\n                        }\n                    }\n                    @media all and (min-width: 601px) and (max-width: 1024px) {\n                        .product-listing-background-image {\n                            background: url('" + backgoundImageUrl + "?width=1024');\n                            background-size: cover;\n                            background-repeat: no-repeat;\n                        }\n                    }\n                    @media all and (min-width: 1025px) {\n                        .product-listing-background-image {\n                            background: url('" + backgoundImageUrl + "?width=1200');\n                            background-size: cover;\n                            background-repeat: no-repeat;\n                        }\n                    }"
                } });
            window.addFullWidth();
        }
        else {
            window.removeFullWidth(true);
        }
        return (React.createElement(React.Fragment, null,
            hasBackgroundImage && backgroundImageStyle,
            hasBackgroundImage &&
                React.createElement("div", { className: "row u-pt-6 _sm-u-pt-10 _md-u-pt-17 u-mb-3 u-relative product-listing-background-image" },
                    dimFilter && React.createElement("div", { className: "s-bg-cover background-image", style: dimFilterStyle }),
                    React.createElement("div", { className: "col-lg-12 col-sm-12 col-xs-12 u-z-1" },
                        React.createElement("div", { className: "o-container u-full-width u-mt-2" },
                            showBreadCrumbNavigation && React.createElement(BreadCrumbNavigation_1.default, null),
                            React.createElement("h1", { className: "u-tc s-hero-s s-themecolor-1 u-my-2" }, heading),
                            React.createElement("div", { className: "o-ingress row u-flex-justify--ce u-mt-2" },
                                React.createElement("div", { className: "col-md-6 col-sm-9 col-xs-12 u-tc s-themecolor-1 s-body-text" }, ingressText)),
                            hasLink &&
                                React.createElement("div", { className: "row u-flex-justify--ce u-mt-3" },
                                    React.createElement("a", { className: "o-button", href: link }, linkText)))))));
    };
    Hero = __decorate([
        mobx_react_1.observer
    ], Hero);
    return Hero;
}(React.Component));
exports.default = Hero;
