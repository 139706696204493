"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
//import { Link } from 'react-router-dom';
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var Store_1 = require("../Store");
var BrandBox = /** @class */ (function (_super) {
    __extends(BrandBox, _super);
    function BrandBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BrandBox.prototype.onClick = function () {
        if (this.props.hasShortcutUrl) {
            Store_1.default.SelectedBrand = this.props;
            //store.SelectedProduct = null;
            //store.SelectedSubcategory = null;
            $([document.documentElement, document.body]).scrollTop(0);
            window.location.href = this.props.url;
        }
        else {
            Store_1.default.SelectedBrand = this.props;
            Store_1.default.loadProducts();
            $([document.documentElement, document.body]).animate({
                scrollTop: 0
            }, Store_1.default._scrollspeed);
        }
    };
    BrandBox.prototype.render = function () {
        var numberOfProductsText = this.props.numberOfProducts + " " + Store_1.default.Translations.numberOfProductsText;
        return (React.createElement("a", { href: this.props.url, onClick: this.onClick, className: "o-product-brand-wrapper col-xs-6 col-sm-4 col-md-3 col-lg-2 u-mb-3" },
            React.createElement("div", { style: { backgroundImage: "url(" + this.props.listingImageUrl + "?height=800&quality=70" + ")" }, className: "o-product-brand-container" },
                React.createElement("div", { className: "filter" })),
            React.createElement("h3", { className: "u-mt-05 u-mb-0" }, this.props.name),
            React.createElement("h3", { className: "u-mb-0 u-mt-0" }, numberOfProductsText))
        //<Link to={this.props.url} onClick={this.onClick} className="o-product-brand-wrapper col-xs-6 col-sm-4 col-md-3 col-lg-2 u-mb-3">
        //    <div style={{ backgroundImage: "url(" + this.props.listingImageUrl + "?height=800&quality=70" + ")" }} className="o-product-brand-container">
        //        <div className="filter" />
        //    </div>
        //    <h3 className="u-mt-05 u-mb-0">{this.props.name}</h3>
        //    <h3 className="u-mb-0 u-mt-0">{numberOfProductsText}</h3>
        //</Link>    
        );
    };
    __decorate([
        mobx_1.action.bound
    ], BrandBox.prototype, "onClick", null);
    BrandBox = __decorate([
        mobx_react_1.observer
    ], BrandBox);
    return BrandBox;
}(React.Component));
exports.default = BrandBox;
