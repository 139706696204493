"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var BrandBox_1 = require("./Elements/BrandBox");
var Store_1 = require("./Store");
var ContentArea_1 = require("./Elements/ContentArea");
var SubcategoryBox_1 = require("./Elements/SubcategoryBox");
var Hero_1 = require("./Elements/Hero");
var react_slick_1 = require("react-slick");
var mobx_1 = require("mobx");
var BrandList = /** @class */ (function (_super) {
    __extends(BrandList, _super);
    function BrandList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            disableSlider: window.innerWidth > 768
        };
        return _this;
    }
    BrandList.prototype.componentDidMount = function () {
        document.title = Store_1.default.currentPageName + " | Hartwall";
        Store_1.default.updateNavigationColor(Store_1.default.containerId);
        window.addEventListener('resize', this.updateWidth.bind(this));
    };
    BrandList.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.updateWidth);
    };
    BrandList.prototype.updateWidth = function () {
        if (this.state.disableSlider === false && window.innerWidth > 768) {
            this.setState({ disableSlider: true });
        }
    };
    BrandList.prototype.onShowAllClick = function () {
        this.setState({ disableSlider: true });
    };
    BrandList.prototype.render = function () {
        var hasSubcategories = Store_1.default.Subcategories !== null && Store_1.default.Subcategories.length > 0;
        var canRenderSlider = this.state.disableSlider === false && hasSubcategories === true && Store_1.default.Subcategories.length > 3;
        var subcategoriesText = Store_1.default.SelectedCategory.subcategoriesText;
        var brandsText = Store_1.default.SelectedCategory.brandsText;
        var linkText = Store_1.default.SelectedCategory.readMoreLinkText || Store_1.default.Translations.readMoreLinkText;
        var sliderSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2
                    }
                }
            ]
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("article", { id: "main-content" },
                React.createElement(Hero_1.default, { backgroundImageUrl: Store_1.default.SelectedCategory.backgroundImageUrl, dimFilter: Store_1.default.SelectedCategory.dimFilter, heading: Store_1.default.SelectedCategory.categoryHeader, ingressText: Store_1.default.SelectedCategory.categoryIngress, link: Store_1.default.SelectedCategory.readMoreLink, linkText: linkText }),
                (hasSubcategories === true) && React.createElement("div", { className: "o-container u-mb-2" },
                    React.createElement("div", { className: "row u-mb-1 _sm-u-mb-2" },
                        React.createElement("div", { className: "col-xs-6" },
                            React.createElement("h2", { className: "s-h3-s u-mt-05 u-mb-0" }, subcategoriesText)),
                        React.createElement("div", { className: "col-xs-6" }, (canRenderSlider === true) && React.createElement("button", { className: "o-button blue show-all _sm-d-none", onClick: this.onShowAllClick.bind(this) }, Store_1.default.Translations.showAllText))),
                    (canRenderSlider === false) && React.createElement("div", { className: "row" }, Store_1.default.Subcategories.map(function (subcategory) {
                        return (React.createElement(SubcategoryBox_1.default, __assign({ key: subcategory.id }, subcategory)));
                    })),
                    (canRenderSlider === true) && React.createElement(react_slick_1.default, __assign({ className: "o-filter-slider" }, sliderSettings), Store_1.default.Subcategories && Store_1.default.Subcategories.map(function (subcategory) {
                        return (React.createElement(SubcategoryBox_1.default, __assign({ key: subcategory.id }, subcategory)));
                    }))),
                React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedCategory.id, language: Store_1.default.currentLanguage, area: "TopContentArea" }),
                React.createElement("div", { className: "o-container" },
                    React.createElement("h3", { className: "s-h3-s u-mt-0 u-mb-1 _sm-u-mb-2 u-pl-1" }, brandsText)),
                React.createElement("div", { className: "o-container u-mb-4" },
                    React.createElement("div", { className: "o-product-listing-wrapper" },
                        Store_1.default.loading &&
                            React.createElement("div", { className: "ui active inverted dimmer" },
                                React.createElement("div", { className: "ui text loader" }, Store_1.default.Translations.loadingText)),
                        React.createElement("div", { className: "row" }, Store_1.default.Brands && Store_1.default.Brands.map(function (brand) {
                            return (React.createElement(BrandBox_1.default, __assign({ key: brand.id }, brand)));
                        }))))),
            React.createElement("section", { id: "related-articles" },
                React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedCategory.id, language: Store_1.default.currentLanguage, area: "BottomContentArea", backupContent: Store_1.default.containerId, backupArea: "ProductContentArea" }))));
    };
    __decorate([
        mobx_1.action
    ], BrandList.prototype, "onShowAllClick", null);
    BrandList = __decorate([
        mobx_react_1.observer
    ], BrandList);
    return BrandList;
}(React.Component));
exports.default = BrandList;
