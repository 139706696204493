"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var ProductList_1 = require("./ProductList");
var BrandList_1 = require("./BrandList");
var ProductDisplay_1 = require("./ProductDisplay");
var Product_1 = require("./Models/Product");
var Brand_1 = require("./Models/Brand");
var SubcategoryDisplay_1 = require("./SubcategoryDisplay");
var Store_1 = require("./Store");
require("jquery");
var CategoryList_1 = require("./CategoryList");
var mobx_react_1 = require("mobx-react");
var ProductsApp = /** @class */ (function (_super) {
    __extends(ProductsApp, _super);
    function ProductsApp(props) {
        var _this = _super.call(this, props) || this;
        Store_1.default.containerId = _this.props.containerId;
        Store_1.default.currentPageName = _this.props.currentPageName;
        Store_1.default.containerUrlSegment = _this.props.containerUrlSegment;
        Store_1.default.containerName = _this.props.containerName;
        Store_1.default.Translations = _this.props.translations;
        Store_1.default.BackgroundImageUrl = _this.props.backgroundImageUrl;
        Store_1.default.DimFilter = _this.props.dimFilter || false;
        Store_1.default.currentLanguage = _this.props.currentLanguage;
        Store_1.default.readMoreLink = _this.props.readMoreLink;
        Store_1.default.disableBrands = _this.props.disableBrands || false;
        Store_1.default.disableCategories = _this.props.disableCategories || false;
        Store_1.default.loadCategories();
        if (_this.props.selectedProduct !== null) { //Landing on ProductDisplay(ProductPage)
            Store_1.default.SelectedProduct = new Product_1.default({ id: _this.props.selectedProduct });
            Store_1.default.SelectedProduct.loadDetails();
            Store_1.default.loadBrands();
            Store_1.default.loadCategories();
        }
        if (_this.props.selectedBrand !== null) { //Landing on ProductList(ProductBrandPage)
            Store_1.default.SelectedBrand = new Brand_1.default(_this.props.selectedBrand);
            Store_1.default.loadProducts();
        }
        if (_this.props.selectedCategory !== null) { //Landing on BrandList(ProductCategoryPage)
            Store_1.default.SelectedCategory = _this.props.selectedCategory;
            Store_1.default.loadBrandsByCategory();
            Store_1.default.loadSubcategories();
        }
        if (_this.props.selectedSubcategory !== null) { //Landing on Subcategory(SubcategoryPage)
            Store_1.default.SelectedSubcategory = _this.props.selectedSubcategory;
            Store_1.default.loadProducts();
        }
        window.onpopstate = function () {
            location.reload();
        };
        return _this;
    }
    ProductsApp.prototype.render = function () {
        return (React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement(react_router_dom_1.Switch, null,
                Store_1.default.SelectedSubcategory && React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":category/" + Store_1.default.SelectedSubcategory.containerUrlSegment + "/:subcategory", component: SubcategoryDisplay_1.default }),
                React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":category/:brand/:product", component: ProductDisplay_1.default }),
                React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":category/:brand", component: ProductList_1.default }),
                React.createElement(react_router_dom_1.Route, { path: Store_1.default.containerUrlSegment + ":category", component: BrandList_1.default }),
                React.createElement(react_router_dom_1.Route, { exact: true, path: Store_1.default.containerUrlSegment, component: CategoryList_1.default }))));
    };
    ProductsApp = __decorate([
        mobx_react_1.observer
    ], ProductsApp);
    return ProductsApp;
}(React.Component));
exports.default = ProductsApp;
