"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var Store_1 = require("../Store");
var BreadCrumbNavigation = /** @class */ (function (_super) {
    __extends(BreadCrumbNavigation, _super);
    function BreadCrumbNavigation() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BreadCrumbNavigation.prototype.onListingPageClick = function () {
        Store_1.default.SelectedBrand = null;
        Store_1.default.SelectedCategories.clear();
        Store_1.default.SelectedProduct = null;
        Store_1.default.SelectedCategory = null;
        Store_1.default.SelectedSubcategory = null;
    };
    BreadCrumbNavigation.prototype.onCategoryPageClick = function () {
        Store_1.default.SelectedBrand = null;
        Store_1.default.SelectedProduct = null;
        Store_1.default.SelectedSubcategory = null;
    };
    BreadCrumbNavigation.prototype.onBrandPageClick = function () {
        Store_1.default.SelectedProduct = null;
        Store_1.default.SelectedSubcategory = null;
        Store_1.default.loadProducts();
    };
    BreadCrumbNavigation.prototype.onSubcategoryPageClick = function () {
    };
    BreadCrumbNavigation.prototype.render = function () {
        var showListingPage = Store_1.default.containerUrlSegment && (Store_1.default.SelectedCategory);
        return (React.createElement("div", { className: "o-breadcrumb-navigation u-tc" },
            React.createElement("ul", { className: "" },
                showListingPage && React.createElement("li", null,
                    React.createElement(react_router_dom_1.Link, { to: Store_1.default.containerUrlSegment, href: Store_1.default.containerUrlSegment, onClick: this.onListingPageClick }, Store_1.default.containerName)),
                Store_1.default.SelectedCategory && React.createElement("li", { className: "" },
                    React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedCategory.url, href: Store_1.default.SelectedCategory.url, onClick: this.onCategoryPageClick }, Store_1.default.SelectedCategory.name)),
                Store_1.default.SelectedBrand && React.createElement("li", { className: "" },
                    React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedBrand.url, href: Store_1.default.SelectedBrand.url, onClick: this.onBrandPageClick }, Store_1.default.SelectedBrand.name)),
                Store_1.default.SelectedSubcategory && React.createElement("li", { className: "" },
                    React.createElement(react_router_dom_1.Link, { to: Store_1.default.SelectedSubcategory.url, href: Store_1.default.SelectedSubcategory.url, onClick: this.onSubcategoryPageClick }, Store_1.default.SelectedSubcategory.name)))));
    };
    __decorate([
        mobx_1.action.bound
    ], BreadCrumbNavigation.prototype, "onListingPageClick", null);
    __decorate([
        mobx_1.action.bound
    ], BreadCrumbNavigation.prototype, "onCategoryPageClick", null);
    __decorate([
        mobx_1.action.bound
    ], BreadCrumbNavigation.prototype, "onBrandPageClick", null);
    __decorate([
        mobx_1.action.bound
    ], BreadCrumbNavigation.prototype, "onSubcategoryPageClick", null);
    BreadCrumbNavigation = __decorate([
        mobx_react_1.observer
    ], BreadCrumbNavigation);
    return BreadCrumbNavigation;
}(React.Component));
exports.default = BreadCrumbNavigation;
