"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var ProductBox_1 = require("./Elements/ProductBox");
var Store_1 = require("./Store");
var ContentArea_1 = require("./Elements/ContentArea");
var Hero_1 = require("./Elements/Hero");
var SubcategoryDisplay = /** @class */ (function (_super) {
    __extends(SubcategoryDisplay, _super);
    function SubcategoryDisplay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SubcategoryDisplay.prototype.componentDidMount = function () {
        Store_1.default.siteName = "Hartwall";
        document.title = Store_1.default.currentPageName + " | " + Store_1.default.siteName;
        //store.updateNavigationColor(store.containerId);
    };
    SubcategoryDisplay.prototype.render = function () {
        var heading = Store_1.default.SelectedSubcategory.heading || Store_1.default.SelectedSubcategory.name;
        return (React.createElement(React.Fragment, null,
            React.createElement(Hero_1.default, { backgroundImageUrl: Store_1.default.SelectedSubcategory.backgroundImageUrl, heading: heading, ingressText: Store_1.default.SelectedSubcategory.ingress, dimFilter: Store_1.default.SelectedSubcategory.dimFilter }),
            React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedSubcategory.id, language: Store_1.default.currentLanguage, area: "TopContentArea" }),
            React.createElement("div", { className: "o-container" },
                React.createElement("h3", { className: "s-h3-s u-mt-0 u-mb-1 _sm-u-mb-2 u-pl-1" }, Store_1.default.SelectedSubcategory.productsText)),
            React.createElement("div", { className: "o-container u-mb-4" },
                React.createElement("div", { className: "o-product-listing-wrapper" },
                    Store_1.default.loading &&
                        React.createElement("div", { className: "ui active inverted dimmer" },
                            React.createElement("div", { className: "ui text loader" }, Store_1.default.Translations.loadingText)),
                    React.createElement("div", { className: "row" }, Store_1.default.Products && Store_1.default.Products.map(function (product) {
                        return (React.createElement(ProductBox_1.default, { key: product.id, product: product }));
                    })))),
            React.createElement(ContentArea_1.default, { content: Store_1.default.SelectedSubcategory.id, language: Store_1.default.currentLanguage, area: "BottomContentArea" })));
    };
    SubcategoryDisplay = __decorate([
        mobx_react_1.observer
    ], SubcategoryDisplay);
    return SubcategoryDisplay;
}(React.Component));
exports.default = SubcategoryDisplay;
